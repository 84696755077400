<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="total"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('notifications.title') }}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ $t('notifications.new',{num:total}) }}

        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="(notification,index) in notifications"
        :key="'notification'+index"
        @click="markAsRead(notification.id)"
      >
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ getText(notification) }}
            </span>
          </p>
          <small class="notification-text">{{ getText(notification,'message') }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="$router.push({name:'owner:notifications'})"
      >
        {{ $t('notifications.show-all') }}

      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'auth/notifications',
      total: 'auth/notificationsTotal',
    }),
  },
  async mounted() {
    await this.$store.dispatch('auth/FETCH_NOTIFICATIONS')
  },
  methods: {
    async markAsRead(id) {
      await this.$store.dispatch('auth/Mark_NOTIFICATION_READ', id)
    },
    getText(row, attr = 'title') {
      if (!row.translate) return row[attr]
      if (attr !== 'message' || !row.attributes) return this.$t(`${row[attr]}`)
      return this.$t(`${row[attr]}`, row.attributes)
    },
  },
}
</script>
